

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";
import SectionServices from "../components/section/services";
import SectionPortfolio from "../components/section/portfolio";
import CardPortfolio from "../components/card_portfolio";
import PortfolioApi from "../services/api/portfolio_api";

function WorkPage(props) {

    const { t, i18n } = useTranslation()

    const _workProcessData = [
        {
            title: "Discovery",
            description: "objectives, larget audience, and urique requirements."
        },
        {
            title: "Design & Develop",
            description: "Itranslate Insights hto tangitle designs, crating intuitive interfaces and seeniess esperiences."
        },
        {
            title: "Test & Launch",
            description: "I rigorously test the product to ensure functionalky and performance before a smooth and successful launch"
        }
    ];

    return (<div className={'min-vh-100'}>


        <div className="container text-dark text-start pt-5 mt-5 pb-4">
            {/*<a href="/" className={'nav-link opacity-25'}>*/}
            {/*    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"*/}
            {/*         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">*/}
            {/*        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
            {/*              d="M5 12h14M5 12l4-4m-4 4 4 4"/>*/}
            {/*    </svg>*/}
            {/*    Back*/}
            {/*</a>*/}
            {/*<br/>*/}
            {/*<h6>{t('nav.contacts')}</h6>*/}
            <h1>Work</h1>


            <p className={'p-2'}>
                My Work Process
            </p>

            <div className="d-flex flex-wrap">

                {
                    _workProcessData.map((item, i) =>  <div className="col-md-4 col-12">
                        <div className="content border shadow-sm rounded p-3 m-2">
                            <small className="text-secondary">
                                0{i+1}
                            </small>

                            <h4 className={'mt-3'}>
                                {item.title}
                            </h4>
                            <p>
                                {item.description}
                            </p>
                        </div>
                    </div>)
                }

            </div>

            {/*<div className="services">*/}

            {/*    <h2 className={'opacity-25'}>Services</h2>*/}

            {/*    <div className="d-flex flex-wrap justify-content-between align-items-start">*/}

            {/*        <div className="col-md-4 col-12 p-2 hover-div">*/}
            {/*            <a href="/services/mobile-develop" target={'_blank'} className={'text-decoration-none d-flex align-items-center'}>*/}
            {/*                <div className="service-item border1 rounded-5">*/}
            {/*                    <img*/}
            {/*                        src="https://www.shutterstock.com/image-vector/man-laptop-computer-3d-icon-600nw-2166535919.jpg"*/}
            {/*                        alt="support" className={'img-fluid'}/>*/}
            {/*                </div>*/}
            {/*                <small className="text-secondary">*/}
            {/*                    <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"*/}
            {/*                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"*/}
            {/*                         viewBox="0 0 24 24">*/}
            {/*                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                              stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>*/}
            {/*                    </svg> support & consultation*/}
            {/*                </small>*/}
            {/*            </a>*/}
            {/*        </div>*/}

            {/*        <div className="col-md-4 col-6 p-2 hover-div">*/}
            {/*            <a href="/services/mobile-develop" target={'_blank'} className={'text-decoration-none d-flex align-items-center'}>*/}
            {/*                <div className="service-item border1 rounded-5">*/}
            {/*                    <img*/}
            {/*                        src={"https://img.freepik.com/free-photo/3d-hand-hold-mobile-phone-with-blank-screen_107791-15086.jpg"}*/}
            {/*                        alt="iphone" className={'img-fluid'}/>*/}
            {/*                </div>*/}
            {/*                <small className="text-secondary">*/}
            {/*                    <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"*/}
            {/*                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"*/}
            {/*                         viewBox="0 0 24 24">*/}
            {/*                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                              stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>*/}
            {/*                    </svg>*/}
            {/*                    mobile develop*/}
            {/*                </small>*/}
            {/*            </a>*/}
            {/*        </div>*/}

            {/*        <div className="col-md-4 col-6 p-2 hover-div">*/}
            {/*            <a href="/services/web-develop" target={'_blank'} className={'text-decoration-none d-flex align-items-center'}>*/}
            {/*                <div className="service-item border1 rounded-5">*/}
            {/*                    <img*/}
            {/*                        src={"https://st2.depositphotos.com/11576988/46953/v/450/depositphotos_469538504-stock-illustration-laptop-isometric-style-portable-computer.jpg"}*/}
            {/*                        alt="macbook" className={'rounded-5 img-fluid'}/>*/}
            {/*                </div>*/}
            {/*                <small className="text-secondary">*/}
            {/*                    <svg className="w-6 h-6 text-gray-800 dark:text-white m-2" aria-hidden="true"*/}
            {/*                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"*/}
            {/*                         viewBox="0 0 24 24">*/}
            {/*                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"*/}
            {/*                              stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>*/}
            {/*                    </svg> web develop*/}
            {/*                </small>*/}
            {/*            </a>*/}
            {/*        </div>*/}


            {/*        /!*<div className="col-md-6 col-6 border rounded-5 shadow p-5">*!/*/}
            {/*        /!*    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" fill="currentColor"*!/*/}
            {/*        /!*         className="bi bi-laptop" viewBox="0 0 16 16">*!/*/}
            {/*        /!*        <path*!/*/}
            {/*        /!*            d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>*!/*/}
            {/*        /!*    </svg>*!/*/}
            {/*        /!*    <p>*!/*/}
            {/*        /!*        <small className={'text-secondary'}>*!/*/}
            {/*        /!*            web develop*!/*/}
            {/*        /!*        </small>*!/*/}
            {/*        /!*    </p>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        /!*<div className="col-md-6 col-6 border rounded-5 shadow p-5">*!/*/}
            {/*        /!*    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="116" fill="currentColor"*!/*/}
            {/*        /!*         className="bi bi-phone" viewBox="0 0 16 16">*!/*/}
            {/*        /!*        <path*!/*/}
            {/*        /!*            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>*!/*/}
            {/*        /!*        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>*!/*/}
            {/*        /!*    </svg>*!/*/}
            {/*        /!*    <p>*!/*/}
            {/*        /!*        <small className={'text-secondary'}>*!/*/}
            {/*        /!*            mobile develop*!/*/}
            {/*        /!*        </small>*!/*/}
            {/*        /!*    </p>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </div>*/}

            {/*</div>*/}

            <div className="d-flex align-items-center justify-content-between vh-100">

                <div className="col-md-6 col-12 d-flex flex-column align-items-start justify-content-center">

                    <h2  className={'opacity-25'}>

                    </h2>
                    <h2 className={'display-3'}>
                        Ready to build something great?
                    </h2>
                    <p className="subtitle mt-3">
                        Let's set up a cel to ciscuss your requirments
                    </p>

                    <a href="/cooperation" className={'btn btn-dark rounded-pill p-3 mt-3'}>Contact me</a>
                </div>

                <div className="col-md-6 col-12">
                    <img src="https://lukyanchuk.com/assets/image/me.jpg" alt="Yaroslav Lukyanchuk" className={'img-fluid rounded'}/>
                </div>

            </div>

            <div className="portfolio">



                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <h2>Portfolio</h2>

                    <a href="/portfolio" target={'_blank'} className={'nav-link'}>View more <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                                                                 xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                 fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                    </svg></a>
                </div>

                <div className="d-flex flex-wrap align-items-center mt-5">

                    <PortfolioApi />

                    <div className="col-md-12 col-12 text-center p-5">
                        <a href="/portfolio" target={'_blank'} className={'nav-link'}>View more <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                                                                     xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                     fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"/>
                        </svg></a>
                    </div>

                </div>
            </div>

            <a href="/" className={'nav-link opacity-25 mt-5'}>
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                </svg>
                Back
            </a>

        </div>
    </div>);
}

export default WorkPage;
